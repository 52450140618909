import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { BoxProps } from '@material-ui/core';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { createSizes } from 'shared/helpers/createSizes';
import DarkButton from 'system/components/Button/DarkButton';

const OurTeamSection = (props: BoxProps) => {
  const { t } = useTranslation('static_pages');

  return (
    <Wrapper as="section" {...props}>
      <ImageArea>
        <Image
          alt=""
          width={550}
          layout="responsive"
          height={490}
          src="/artifacts/images/homepage/team.webp"
          sizes={createSizes({ xs: '80vw', sm: '400px', md: '550px' })}
        />
      </ImageArea>
      <TitleArea>
        <Title>{t('homepage.ourTeam.title')}</Title>
        <Subtitle>{t('homepage.ourTeam.subtitle')}</Subtitle>
      </TitleArea>

      <DescriptionArea>
        <Description>{t('homepage.ourTeam.description')}</Description>
        <Box mt={2} textAlign={{ xs: 'center', md: 'start' }}>
          <Link href={'/about-us'} passHref>
            <DarkButton component="a" target="_blank" rounded size="lg">
              {t('homepage.ourTeam.buttonLabel')}
            </DarkButton>
          </Link>
        </Box>
      </DescriptionArea>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: grid;
  grid-template-areas:
    'title'
    'image'
    'description';
  justify-items: center;
  grid-gap: ${({ theme }) => theme.spacing(3)}px;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(0, 4)};
    grid-template-areas:
      'image title'
      'image description';
    justify-items: start;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    grid-gap: ${({ theme }) => theme.spacing(10)}px;
    grid-row-gap: ${({ theme }) => theme.spacing(3)}px;
  }
`;

const ImageArea = styled(Box)`
  grid-area: image;
  width: min(80vw, 400px);
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-self: end;
    width: clamp(200px, 550px, 40vw);
  }
`;
const TitleArea = styled(Box)`
  grid-area: title;
  align-self: end;
  text-align: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: start;
  }
`;

const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  line-height: 160%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 36px;
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;

const Subtitle = styled(Typography)`
  grid-area: subtitle;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
  }
`;

const DescriptionArea = styled(Box)`
  grid-area: description;
  align-self: start;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0;
  }
`;

const Description = styled(Typography)`
  font-size: 14px;
  line-height: 168%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 16px;
    max-width: 600px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 480px;
  }
`;

export default OurTeamSection;
